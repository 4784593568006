import { IBoard } from "../Interfaces/Kanban";

export const ApiMockResponse: IBoard[] = [
  {
    id: 1651319512266.7095,
    title: "Backlog",
    cards: [
      {
        id: 1651319552926.0933,
        title: "Task1",
        labels: [{ color: "#cf61a1", text: "Urgent" }],
        date: "2022-05-05",
        tasks: [
          { id: 1651319625559.8025, completed: true, text: "Task1_subtask1" },
          { id: 1651319629650.8945, completed: true, text: "Task1_subtask2" },
          { id: 1651319633774.9905, completed: true, text: "Task1_subtask3" },
        ],
        desc: "Task1 Detail Description",
      },
      {
        id: 1651319568365.593,
        title: "Task2",
        labels: [{ color: "#1ebffa", text: "Frontend" }],
        date: "",
        tasks: [],
      },
    ],
  },
  {
    id: 1651319523126.113,
    title: "Progress",
    cards: [],
  },
  {
    id: 1651319530017.122,
    title: "In-Review/Test",
    cards: [],
  },
  {
    id: 1651319535931.4182,
    title: "Done",
    cards: [],
  },
];
